<template>
	<div class="page-container page-sign page-restore-pass">
		<div class="section-sign">
			<img :src="img_logo" width="200" height="60" alt="bigminer" class="BBzbG">
            <h1 class="_2v5Ij _2nIGN"><span>{{ $t('restore-title') }}</span></h1>
            <ValidationObserver v-slot="{ invalid }">
            <form method="post">
				<ValidationProvider name="email" rules="required|email" v-slot="{ errors }">
                    <input class="_3ziJm HfRjv" type="email" name="email" v-model="email" :placeholder="$t('placeholder-email')" autocomplete="off">
                    <div class="_35czU err-msg">{{ errors[0] }}</div>
                </ValidationProvider>
                <button class="ufT_1 _2JhAE" type="button"
                    @click="handleResetSendMail"
                    :disabled="invalid"><span>{{ $t('button-reset-password') }}</span></button>
                <div class="_2kpKS _2sy1g">
                    <span>{{ $t('return-to') }}</span>&nbsp;
                    <router-link class="_3EH0- _3E6dh _2liT2 _3fnIQ" :to="{name: 'Login'}">
                        <span>{{ $t('button-login') }}</span>
                    </router-link>
                </div>
            </form>
            </ValidationObserver>
        </div>
    </div>
</template>
<script>
import img_logo from '@/assets/bigminer-logo.svg'

export default{
	data() {
		return {
            email: '',
			img_logo,
		}
	},
    notifications: {
        showMessage: {
            title: '',
            message: '',
            type: 'error'
        }
    },
	methods: {
        handleResetSendMail() {
            let loader = this.$loading.show();
            this.$axios.post(
                '/auth/email/password/reset',
                {
                    email: this.email,
                    lang: this.$i18n.locale
                }
            ).then(res => {
                loader.hide();
                if (res) {
                    if (res.data && res.data.success) {
                        this.showMessage({
                            message: this.$t('email-sent-success'),
                            type: 'success'
                        });
                        this.$router.push({ name: 'ConfirmPass' });
                    } else {
                        this.showMessage({ message: this.$t('email-sent-fail') });
                    }
                } else {
                    console.log('res', res)
                    this.showMessage({ message: this.$t('email-sent-fail') });
                }
            }).catch(err => {
                loader.hide();
                if (err && err.statusCode === 429) {
                    this.showMessage({ message: this.$t('rate-limit-exceeded') });
                } else {
                    console.log('err', err)
                    this.showMessage({ message: this.$t('email-sent-fail') });
                }
            });
        },
	}
}
</script>
<style rel="stylesheet/scss" lang="scss">
.HfRjv {
    display: block;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 12px 20px;
    width: 100%;
    outline: 0;
    border: 1px solid transparent;
    border-radius: 3px;
    color: var(--hos-light-grey);
    font-size: 14px;
    line-height: 20px;
    -webkit-transition: border-color .15s ease-in-out;
    -o-transition: border-color ease-in-out .15s;
    transition: border-color .15s ease-in-out;
    background: var(--hos-extra-dark-bg);
}
.HfRjv:focus {
    border-color: var(--hos-border-light--hover);
}
</style>
