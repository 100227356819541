<template>
    <div class="lang-switcher">
        <ul class="lang-switcher-list">
            <li class="" v-for="locale in locales"
                :key="locale.code" @click="changeLocale(locale.code)"
                :class="{ active: $i18n.locale === locale.code }">
                {{ locale.name }}
            </li>
        </ul>
    </div>
</template>
<script>
export default {
    data: () => ({
        locales: [{
            code: 'zh',
            name: '中文'
        }, {
            code: 'en',
            name: 'English'
        }]
    }),
    methods: {
        changeLocale(locale) {
            const parentForm = this.$parent.$refs.form;
            this.$i18n.locale = locale;
            localStorage.lang = locale;
            if (parentForm && typeof parentForm.validate === 'function') {
                parentForm.validate();
            }
        }
  }
}
</script>
<style>
.lang-switcher,.lang-switcher-list {
    position: relative
}
.lang-switcher {
    padding-top: 30px;
    text-align: center
}

.lang-switcher,.lang-switcher-list {
    position: relative
}
.lang-switcher-list {
    padding: 0;
    margin: 0;
    list-style: none
}

.lang-switcher-list li {
    cursor: pointer;
    display: inline-block;
    margin: 0 15px;
    font-size: 14px
}

.lang-switcher-list li:hover {
    color: var(--hos-grey-color)
}

.lang-switcher-list li.active {
    font-weight: 800;
    color: var(--hos-white-text)
}
@media screen and (max-width:768px) {
    .lang-switcher-list li {
        margin: 10px 15px
    }

    .lang-switcher {
        padding-bottom: 20px
    }
}
</style>
