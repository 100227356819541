<template>
	<div class="page-container page-sign page-signin">
		<div class="section-sign">
			<img :src="img_logo" width="200" height="60" alt="bigminer" class="BBzbG">
			<h1 class="_2nIGN"><span>{{ $t('login-title') }}</span></h1>
            <ValidationObserver ref="form" v-slot="{ invalid }">
			<form method="post">
				<ValidationProvider name="login" rules="required" v-slot="{ errors }">
                    <input type="text" class="form-control" name="username" :placeholder="$t('placeholder-login-email')" value="" autocomplete="username" autofocus="" v-model="loginForm.username">
                    <div class="err-msg">{{ errors[0] }}</div>
				</ValidationProvider>
				<ValidationProvider name="password" rules="required" v-slot="{ errors }">
					<div class="_3ae-B">
						<input :type="pwdType" class="form-control"
							name="password" :placeholder="$t('placeholder-password')" value=""
							v-on:keyup.enter="handleLogin"
							autocomplete="current-password" v-model="loginForm.password">
						<img src="@/assets/c2f828d1.svg" class="_2DYkz" alt="" @click="showPwd">
					</div>
					<div class="err-msg">{{ errors[0] }}</div>
				</ValidationProvider>
				<div class="_3TyI5">
					<label class="_1eHb1">
						<input type="checkbox" name="remember" v-model="rememberLogin" class="uW_Mi">
						<span class="_2Ly8u"><span>{{ $t('login-remember-login') }}</span></span>
					</label>
					<router-link class="_1qkFv" :to="{name: 'RestorePass'}">
						<span>{{ $t('login-forget-password') }}</span>
					</router-link>
				</div>
				<button class="_2JhAE" type="button"
                    @click="handleLogin"
                    :disabled="invalid">
					<div class="_2DK96">
						<div class="_2DK96">
							<span>{{ $t('button-login') }}</span>
						</div>
					</div>
				</button>
				<div class="_2sy1g _3p2L7">
					<span>{{ $t('login-forget-password') }}</span>
					<router-link class="_3E6dh _3fnIQ" :to="{name: 'RestorePass'}"><span>{{ $t('button-restore') }}</span></router-link>
				</div>
				<div class="_2sy1g">
					<span>{{ $t('login-no-account') }}</span>
					<router-link class="_3E6dh _3fnIQ" :to="{name: 'Register'}"><span>{{ $t('button-register') }}</span></router-link>
				</div>
			</form>
            </ValidationObserver>
		</div>
        <locale-switcher />
	</div>
</template>
<script>
import Vue from 'vue'
import img_logo from '@/assets/bigminer-logo.svg'
import LocaleSwitcher from '@/components/LocaleSwitcher'
import VueCookies from 'vue-cookies'

Vue.use(VueCookies);

export default{
    components: {
        LocaleSwitcher
    },
	data: () => ({
        loginForm: {
            username: '',
            password: '',
        },
        rememberLogin: true,
        pwdType: 'password',
        img_logo,
	}),
    notifications: {
        showMessage: {
            title: '',
            message: '',
            type: 'error'
        }
    },
	methods: {
		showPwd() {
			if (this.pwdType === 'password') {
				this.pwdType = 'text';
			} else {
				this.pwdType = 'password';
			}
		},
		handleLogin() {
            let loader = this.$loading.show();
            this.$axios.post(
                '/auth/email/login',
                this.loginForm
            ).then(res => {
                loader.hide();
                let data = res.data;
                this.$cookies.set('jwtt', data.jwtt, '1m', '/', process.env.VUE_APP_JWTT_DOMAIN);
                this.$store.commit(this.rememberLogin ? 'setTokenLocal' : 'setToken', data.access_token);
                window.location.href = process.env.VUE_APP_REDIRECT_URI;
            }).catch(err => {
                loader.hide();
                if (err.statusCode === '429') {
                    this.showMessage({ message: this.$t('request-frequent') });
                } else {
                    this.showMessage({ message: this.$t('email-password-incorrect') });
                }
            });
		},
	}
}
</script>
