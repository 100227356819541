var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page-container page-sign page-confirm-pass"},[_c('div',{staticClass:"section-sign"},[_c('img',{staticClass:"BBzbG",attrs:{"src":_vm.img_logo,"width":"200","height":"60","alt":"bigminer"}}),_c('h1',{staticClass:"_1UxO1 _2nIGN"},[_c('span',[_vm._v(_vm._s(_vm.$t('title-confirm-pass')))])]),_c('span',{staticClass:"_2x4ex _5OBA_"}),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',{attrs:{"method":"post"}},[_c('div',[_c('ValidationProvider',{attrs:{"name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.email),expression:"email"}],staticClass:"_194s1 HfRjv",attrs:{"type":"email","name":"email","placeholder":"Email","autocomplete":"off"},domProps:{"value":(_vm.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.email=$event.target.value}}}),_c('div',{staticClass:"_2x4ex _5OBA_"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"token","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.token),expression:"token"}],staticClass:"_194s1 HfRjv",attrs:{"type":"text","name":"token","placeholder":"Token","autocomplete":"off"},domProps:{"value":(_vm.token)},on:{"input":function($event){if($event.target.composing){ return; }_vm.token=$event.target.value}}}),_c('div',{staticClass:"_2x4ex _5OBA_"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"password","rules":"required|min:8"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password),expression:"password"}],staticClass:"_194s1 HfRjv",attrs:{"type":"password","name":"new_password","placeholder":_vm.$t('placeholder-password'),"autocomplete":"off"},domProps:{"value":(_vm.password)},on:{"input":function($event){if($event.target.composing){ return; }_vm.password=$event.target.value}}}),_c('div',{staticClass:"_2x4ex _5OBA_"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"confirm","rules":"required|confirmed:password","data-vv-as":"password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.confirmPassword),expression:"confirmPassword"}],staticClass:"_194s1 HfRjv",attrs:{"type":"password","name":"confirmPassword","placeholder":_vm.$t('placeholder-confirm-password'),"autocomplete":"off"},domProps:{"value":(_vm.confirmPassword)},on:{"input":function($event){if($event.target.composing){ return; }_vm.confirmPassword=$event.target.value}}}),_c('div',{staticClass:"_2x4ex _5OBA_"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"_1hD5w Hinvx"},[_c('span',[_vm._v(_vm._s(_vm.$t('tips-password')))])]),_c('button',{staticClass:"_1Zr0Q _2JhAE",attrs:{"type":"button","disabled":invalid},on:{"click":_vm.handleResetPassword}},[_c('div',{staticClass:"_2DK96"},[_c('div',{staticClass:"_2DK96",staticStyle:{"visibility":"initial"}},[_c('span',[_vm._v(_vm._s(_vm.$t('button-reset-password')))])])])]),_c('div',{staticClass:"_10cLx _2sy1g"},[_c('span',[_vm._v(_vm._s(_vm.$t('return-to')))]),_vm._v(" "),_c('a',{staticClass:"_20CdG _3E6dh _1_B_0 _3fnIQ",attrs:{"href":"/login/"}},[_c('span',[_vm._v(_vm._s(_vm.$t('button-login')))])])])])]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }