<template>
	<div class="page-container page-sign page-signup">
		<div class="section-sign">
            <img :src="img_logo" width="200" height="60" alt="bigminer" class="_37ARq BBzbG">
            <h1 class="_3G6vx _2nIGN"><span>{{ $t('register-title') }}</span></h1>
            <ValidationObserver v-slot="{ invalid }">
            <form method="post">
				<ValidationProvider name="email" rules="required|email" v-slot="{ errors }">
                    <input type="email" class="_3TJIy form-control" name="email" autocomplete="off" placeholder="Email" autofocus="" value="" v-model="registerForm.email">
                    <div class="_35czU err-msg">{{ errors[0] }}</div>
                </ValidationProvider>
				<ValidationProvider name="login_only" rules="required|min:4|login" v-slot="{ errors }">
                    <input type="text" class="_3TJIy form-control" name="login" autocomplete="off" :placeholder="$t('placeholder-login')" value="" v-model="registerForm.login">
                    <div class="_35czU err-msg">{{ errors[0] }}</div>
                </ValidationProvider>
				<ValidationProvider name="password" rules="required|min:8|password" v-slot="{ errors }">
                    <div class="_179gL _3ae-B">
                        <input :type="pwdType" class="_3TJIy form-control" name="password" :placeholder="$t('placeholder-password')" autocomplete="off" value="" v-model="registerForm.password">
                        <img src="@/assets/c2f828d1.svg" class="aI5uF _2DYkz" alt="" @click="showPwd">
                    </div>
                    <div class="_3knjR err-msg">{{ errors[0] }}</div>
                    <div class="_3PS26 Hinvx"><span>{{ $t('tips-password') }}</span></div>
                </ValidationProvider>
                <button class="_1IQKG _2JhAE" type="button"
                    @click="handleRegister"
                    :disabled="invalid">
                    <span>{{ $t('button-register') }}</span></button>
            </form>
            </ValidationObserver>
        </div>
        <div class="usUNS _2sy1g">
            <span>{{ $t('register-have-account') }}</span>
            <router-link class="_3FX32 _3E6dh _3U0Hl _3fnIQ" :to="{name: 'Login'}"><span>{{ $t('button-login') }}</span></router-link>
        </div>
    </div>
</template>
<script>
import img_logo from '@/assets/bigminer-logo.svg'
import { extend } from 'vee-validate';
import i18n from '@/i18n'

extend('password', {
    validate: pw => {
        if (/[A-Z]/.test(pw) &&
            /[a-z]/.test(pw) &&
            /[0-9]/.test(pw) &&
            /[!@#$%?^&*()_~+=<>';:`]/.test(pw)) {
            return true;
        } else {
            return i18n.t('tips-password-not-strong');
        }
    }
});

extend('login', {
    validate: input => {
        if (/^[A-Za-z]/.test(input)) {
            if (/^[0-9A-Za-z]*$/.test(input)) {
                return true;
            } else {
                return i18n.t('tips-login-not-valid');
            }
        } else {
            return i18n.t('tips-login-first-char');
        }
    }
});

export default{
	data: () => ({
        registerForm: {
            email: '',
            login: '',
            password: '',
        },
        pwdType: 'password',
        img_logo,
	}),
    notifications: {
        showMessage: {
            title: '',
            message: '',
            type: 'error'
        }
    },
	methods: {
		showPwd() {
			if (this.pwdType === 'password') {
				this.pwdType = 'text';
			} else {
				this.pwdType = 'password';
			}
		},
		handleRegister() {
            let loader = this.$loading.show();
            this.$axios.post(
                '/auth/email/register',
                Object.assign({
                    lang: this.$i18n.locale
                }, this.registerForm)
            ).then(res => {
                loader.hide();
                if (res.data.success) {
                    /*
                    // send mail
                    this.$axios.post('/auth/email/verify', { send: true });
                    this.$router.push({ name: 'VerifyMail' });
                    */
                    this.showMessage({
                        message: this.$t('register-success'),
                        type: 'success'
                    });
                    this.$router.push({ name: 'Login' });
                } else {
                    this.showMessage({ message: this.$t('submit-fail') });
                }
            }).catch((res) => {
                loader.hide();
                let msg = 'submit-fail';
                const responseMessage = res.message;
                if (responseMessage) {
                    if (responseMessage.match('EMAIL_ALREADY_REGISTERED')) {
                        msg = 'email-already-registered';
                    } else if (responseMessage.match('LOGIN_ALREADY_REGISTERED')) {
                        msg = 'login-already-registered';
                    } else if (responseMessage.match('Rate limit exceeded')) {
                        msg = 'rate-limit-exceeded';
                    }
                }
                this.showMessage({ message: this.$t(msg) });
            });
		},
	}
}
</script>
<style rel="stylesheet/scss" lang="scss">
.Hinvx {
    font-size: 12px;
    color: var(--hos-grey-color)
}
</style>
