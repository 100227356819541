import Vue from 'vue'
import Router from 'vue-router'
import LoginComponent from '@/views/Login';
import RegisterComponent from '@/views/Register';
import RestorePassComponent from '@/views/RestorePass';
import ConfirmPassComponent from '@/views/ConfirmPass';
import VerifyMailComponent from '@/views/VerifyMail';
import AccountComponent from '@/views/Account';
import store from './store'

Vue.use(Router)

const DEFAULT_TITLE = 'bigminer';
const TITLE_SUFFIX = ' - bigminer'
const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
        path: '/login',
        name: 'Login',
        component: LoginComponent,
        meta: { title: 'Login' + TITLE_SUFFIX }
    },
    {
        path: '/logout',
        name: 'Logout',
        component: {
            beforeRouteEnter(to, from, next) {
                store.commit('delToken');
                next({ name: 'Login' });
            }
        }
    },
    {
        path: '/register',
        name: 'Register',
        component: RegisterComponent,
        meta: { title: 'Register' + TITLE_SUFFIX }
    },
    {
        path: '/restore-pass',
        name: 'RestorePass',
        component: RestorePassComponent,
        meta: { title: 'Restore password' + TITLE_SUFFIX }
    },
    {
        path: '/confirm-pass',
        name: 'ConfirmPass',
        component: ConfirmPassComponent,
        meta: { title: 'Confirm password' + TITLE_SUFFIX }
    },
    {
        path: '/verify-mail',
        name: 'VerifyMail',
        component: VerifyMailComponent,
        meta: { title: 'Verify Email' + TITLE_SUFFIX }
    },
    {
        path: '/account',
        name: 'Account',
        component: AccountComponent,
        meta: { title: 'Account' + TITLE_SUFFIX }
    },
    {
        path: '*',
        redirect: '/login',
    }
  ]
});

router.afterEach((to) => {
    Vue.nextTick(() => {
        document.title = to.meta.title || DEFAULT_TITLE;
    });
});

export default router;
