import Vue from 'vue';
import VueI18n from 'vue-i18n';
import en from "vee-validate/dist/locale/en.json";
import zh from "vee-validate/dist/locale/zh_CN.json";


Vue.use(VueI18n)

function loadLocaleMessages () {
  const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const messages = {}
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key)
    }
  })
    messages['en'].validation = en.messages;
    messages['zh'].validation = zh.messages;
  return messages
}

const initLang = localStorage.lang;
export default new VueI18n({
    locale: initLang && ['en', 'zh'].indexOf(initLang) > -1 ? initLang : (process.env.VUE_APP_I18N_LOCALE || 'zh'),
    fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'zh',
    messages: loadLocaleMessages()
})
