import Vue from 'vue'
import Vuex from 'vuex'
import VueCookies from 'vue-cookies'

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        token: ''
    },
    mutations: {
        setToken(state, token) {
            state.token = token;
            sessionStorage.token = token;
        },
        setTokenLocal(state, token) {
            state.token = token;
            localStorage.token = token;
        },
        delToken(state) {
            state.token = '';
            sessionStorage.removeItem('token');
            localStorage.removeItem('token');
            VueCookies.remove('jwtt');
            VueCookies.remove('jwtt', '/', process.env.VUE_APP_JWTT_DOMAIN);
        }
    }
})
