import Vue from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'
import store from './store'
import VueNotifications from 'vue-notifications'
import VueToasted from 'vue-toasted'
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import styles from './styles/base.scss'
import i18n from './i18n'
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import './vee-validate';

Vue.use(Loading, {
    color: '#fff',
    backgroundColor: '#21252b',
    width: 50,
    height: 50,
});
Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);

// Toast
function toast ({ message, type, timeout }) {
    if (type === VueNotifications.types.warn) type = 'show';
    return Vue.toasted[type](message, { duration: timeout, position: 'top-center' });
}
Vue.use(VueToasted);
const options = {
  success: toast,
  error: toast,
  info: toast,
  warn: toast
};
Vue.use(VueNotifications, options);

axios.interceptors.request.use(config => {
    let token = sessionStorage.token || localStorage.token;
    if (token) {
        config.headers.Authorization = `bearer ${token}`;
    }
    return config;
}, err => {
    return Promise.reject(err);
});

axios.interceptors.response.use((response) => {
    return response;
}, (err) => {
    if (err.response) {
        switch (err.response.status) {
            case 401:
                store.commit('delToken');
                router.push('/login').catch(err => {
                    if (err.name != "NavigationDuplicated") {
                        throw err;
                    }
                });
        }
    }
    return Promise.reject(err.response.data)
});

Vue.prototype.$axios = axios;

Vue.config.productionTip = false;

new Vue({
    router,
    store,
    styles,
    i18n,
    render: h => h(App)
}).$mount('#app');
