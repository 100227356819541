<template>
	<div class="page-container page-sign page-verify-mail">
		<div class="section-sign">
			<img :src="img_logo" width="200" height="60" alt="bigminer" class="BBzbG">
			<h1 class="_2nIGN"><span>Verify Mail</span></h1>
            <ValidationObserver v-slot="{ invalid }">
			<form method="post">
				<ValidationProvider name="code" rules="required" v-slot="{ errors }">
                    <input type="text" class="form-control" name="code" placeholder="" value="" autocomplete="off" autofocus="" v-model="code">
                    <div class="err-msg">{{ errors[0] }}</div>
				</ValidationProvider>
                <div class="_1qkFv btn-link" @click="resendMail">
                    <span style="float: right">Resend mail</span>
                </div>
				<button class="_2JhAE" type="button"
                    @click="handleSubmit"
                    :disabled="invalid">
					<div class="_2DK96">
						<div class="_2DK96">
							<span>Submit</span>
						</div>
					</div>
				</button>
			</form>
            </ValidationObserver>
		</div>
		<div class="_1k5lX">
			<ul class="_2RXFn">
				<li class="">中文</li>
				<li class="_29bft">English</li>
			</ul>
		</div>
	</div>
</template>
<script>
import img_logo from '@/assets/bigminer-logo.svg'

export default{
	data: () => ({
        code: '',
        img_logo,
	}),
    notifications: {
        showMessage: {
            title: '',
            message: '',
            type: 'error'
        }
    },
	methods: {
        resendMail() {
            this.$axios.post(
                '/auth/email/verify',
                { send: true }
            ).then(res => {
                if (res.statusCode != 401) {
                    if (res.data.success !== false) {
                        this.showMessage({
                            message: 'Email has been successfully sent.',
                            type: 'success'
                        });
                    } else {
                        if (res.data.message === 'REGISTRATION.EMAIL_VERIFIED') {
                            this.showMessage({ message: 'Account already activated.' });
                            this.$router.push({ name: 'Account' });
                        }
                    }
                } else {
                    // TODO
                }
            }).catch(err => {
                console.log(err);
            });
            
        },
		handleSubmit() {
            this.loading = true;
            this.$axios.post(
                '/auth/email/verify',
                { token: this.code }
            ).then(res => {
                this.loading = false;
                console.log(res);
                if (res.statusCode != 401) {
                    if (res.data.access_token) {
                        let token = res.data.access_token;
                        this.$store.commit('setToken', token);
                        this.$router.push({ name: 'Account' });
                    } else {
                        var type = res.data.message;
                        if (type === 'REGISTRATION.EXPIRED_EMAIL_TOKEN') {
                            this.showMessage({ message: 'Expired activation code.' });
                        } else if (type === 'REGISTRATION.EMAIL_VERIFIED') {
                            this.showMessage({ message: 'Account already activated.' });
                        } else {
                            this.showMessage({ message: 'Invalid activation code.' });
                        }
                    }
                } else {
                    // TODO
                }
            }).catch(err => {
                console.log(err);
            });
		},
	}
}
</script>
<style rel="stylesheet/scss" lang="scss">
.BBzbG {
    width: 200px;
    display: block;
    margin: 10px auto
}

._2nIGN {
    font-size: 15px;
    text-align: center;
    font-weight: 600;
    letter-spacing: .5px;
    margin: 20px auto 15px
}

._3ae-B {
    position: relative
}

._2DYkz {
    height: 18px;
    position: absolute;
    right: 15px;
    top: calc(50% - 9px);
    cursor: pointer;
    opacity: .5
}

._2DYkz:hover {
    opacity: 1
}

._2JhAE {
    margin: 20px auto 0;
    width: 100%;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 12px 16px;
    outline: 0;
    border: none;
    color: var(--hos-white-text);
    text-align: center;
    text-decoration: none;
    font-size: 15px;
    font-weight: 700;
    line-height: 1.3333333;
    cursor: pointer;
    border-radius: 3px;
    background-color: var(--hos-action-color)
}

._2JhAE:disabled {
    opacity: .5;
    cursor: default
}
@media screen and (min-width:376px) {
    ._2JhAE {
        margin: 40px auto 0
    }
}
._1eHb1 {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none
}

._2Ly8u {
    margin-left: 7px;
    font-size: 14px;
    letter-spacing: .1px;
    font-weight: 400
}

.uW_Mi {
    position: relative;
    outline: none;
    width: 14px;
    height: 14px;
    border-radius: 2px;
    opacity: .5;
    cursor: pointer;
    -webkit-transition: all .2s ease-out;
    -o-transition: all .2s ease-out;
    transition: all .2s ease-out
}

.uW_Mi:hover {
    opacity: 1
}

._3TyI5 {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-flow: column;
    flex-flow: column
}
@media screen and (min-width:350px) {
    ._3TyI5 {
        -ms-flex-flow: row;
        flex-flow: row
    }
}
._2sy1g {
    text-align: center;
    margin-top: 30px;
    font-size: 14px;
    color: var(--hos-white-color)
}

._3fnIQ {
    margin-left: 5px
}

._1qkFv,._3E6dh {
    font-weight: 700;
    line-height: 22px;
    font-size: 14px;
    color: var(--hos-link-color);
    text-decoration: none
}

._1qkFv {
    display: none;
    line-height: 23px
}

@media screen and (min-width:351px) {
    ._1qkFv {
        display: block
    }
}

._3p2L7 {
    display: block
}

@media screen and (min-width:351px) {
    ._3p2L7 {
        display: none
    }
}
._1k5lX {
    padding-top: 30px;
    text-align: center
}

._1k5lX,._2RXFn {
    position: relative
}

._2RXFn {
    padding: 0;
    margin: 0;
    list-style: none
}

._2RXFn li {
    cursor: pointer;
    display: inline-block;
    margin: 0 15px;
    font-size: 14px
}

._2RXFn li:hover {
    color: var(--hos-grey-color)
}

._2RXFn li._29bft {
    font-weight: 800;
    color: var(--hos-white-text)
}

@media screen and (max-width:768px) {
    ._3Vk6J {
        padding-top: 20px
    }

    ._3E6dh {
        font-size: 14px
    }

    ._2RXFn li {
        margin: 10px 15px
    }

    ._1k5lX {
        padding-bottom: 20px
    }
}
.btn-link {
    cursor: pointer;
}
</style>
