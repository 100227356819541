<template>
	<div class="page-container page-sign page-confirm-pass">
		<div class="section-sign">
			<img :src="img_logo" width="200" height="60" alt="bigminer" class="BBzbG">
            <h1 class="_1UxO1 _2nIGN"><span>{{ $t('title-confirm-pass') }}</span></h1>
            <span class="_2x4ex _5OBA_"></span>
            <ValidationObserver v-slot="{ invalid }">
            <form method="post">
                <div>
                    <ValidationProvider name="email" rules="required|email" v-slot="{ errors }">
                        <input class="_194s1 HfRjv" type="email" name="email" placeholder="Email" autocomplete="off" v-model="email">
                        <div class="_2x4ex _5OBA_">{{ errors[0] }}</div>
                    </ValidationProvider>
                    <ValidationProvider name="token" rules="required" v-slot="{ errors }">
                        <input class="_194s1 HfRjv" type="text" name="token" placeholder="Token" autocomplete="off" v-model="token">
                        <div class="_2x4ex _5OBA_">{{ errors[0] }}</div>
                    </ValidationProvider>
                    <ValidationProvider name="password" rules="required|min:8" v-slot="{ errors }">
                        <input class="_194s1 HfRjv" type="password" name="new_password" :placeholder="$t('placeholder-password')" autocomplete="off" v-model="password">
                        <div class="_2x4ex _5OBA_">{{ errors[0] }}</div>
                    </ValidationProvider>
                    <ValidationProvider name="confirm" rules="required|confirmed:password" v-slot="{ errors }" data-vv-as="password">
                        <input class="_194s1 HfRjv" type="password" name="confirmPassword" :placeholder="$t('placeholder-confirm-password')" autocomplete="off" v-model="confirmPassword">
                        <div class="_2x4ex _5OBA_">{{ errors[0] }}</div>
                    </ValidationProvider>
                </div>
                <div class="_1hD5w Hinvx">
                    <span>{{ $t('tips-password') }}</span>
                </div>
                <button class="_1Zr0Q _2JhAE" type="button"
                    @click="handleResetPassword"
                    :disabled="invalid">
                    <div class="_2DK96">
                        <div class="_2DK96" style="visibility: initial;">
                            <span>{{ $t('button-reset-password') }}</span>
                        </div>
                    </div>
                </button>
                <div class="_10cLx _2sy1g">
                    <span>{{ $t('return-to') }}</span> <a class="_20CdG _3E6dh _1_B_0 _3fnIQ" href="/login/"><span>{{ $t('button-login') }}</span></a>
                </div>
            </form>
            </ValidationObserver>
        </div>
    </div>
</template>
<script>
import img_logo from '@/assets/bigminer-logo.svg'

export default{
	data() {
		return {
            email: '',
            token: '',
            password: '',
            confirmPassword: '',
			img_logo,
		}
	},
    notifications: {
        showMessage: {
            title: '',
            message: '',
            type: 'error'
        }
    },
	methods: {
        handleResetPassword() {
            let loader = this.$loading.show();
            this.$axios.post(
                '/auth/email/password/reset/token',
                {
                    email: this.email,
                    password: this.password,
                    token: this.token,
                }
            ).then(res => {
                loader.hide();
                if (res.data.success !== false) {
                    this.showMessage({
                        message: this.$t('password-restore-success'),
                        type: 'success'
                    });
                    this.$router.push({ name: 'Login' });
                } else {
                    this.showMessage({ message: this.$t('submit-fail') });
                }
            }).catch(err => {
                loader.hide();
                if (err.statusCode === '429') {
                    this.showMessage({ message: this.$t('request-frequent') });
                } else {
                    this.showMessage({ message: this.$t('submit-fail') });
                }
            });
        },
	}
}
</script>
<style rel="stylesheet/scss" lang="scss">
.HfRjv {
    display: block;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 12px 20px;
    width: 100%;
    outline: 0;
    border: 1px solid transparent;
    border-radius: 3px;
    color: var(--hos-light-grey);
    font-size: 14px;
    line-height: 20px;
    -webkit-transition: border-color .15s ease-in-out;
    -o-transition: border-color ease-in-out .15s;
    transition: border-color .15s ease-in-out;
    background: var(--hos-extra-dark-bg);
}
.HfRjv:focus {
    border-color: var(--hos-border-light--hover);
}
@media screen and (min-width: 376px) {
    ._5OBA_ {
        font-size: 13px;
        height: 27px;
    }
}
._5OBA_ {
    font-size: 12px;
    font-weight: 400;
    color: var(--hos-red-color);
    height: 20px;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: end;
    justify-content: flex-end;
    padding-right: 2px;
}
</style>
